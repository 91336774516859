import React from 'react'
import Header from '../Header'
import { Card, CardBody, Row, Col } from 'reactstrap'
import './AboutUsPage.css' 
import image from '../../../assets/images/slider/img1.jpg'
export const AboutUsPage = () => {
    return (
        <React.Fragment>
            <Header />
            <div className="page-content ">
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg="4" className="about-text">
                                <h2>YOUR ULTIMATE RETREAT</h2>
                                <p>
                                    Discover Golden Blossom Imperial Resort, a destination unlike any other where luxury isn't just a feature but a way of life. 
                                    Nestled along the enchanting highway to Ayodhya and founded by Rajendra Singh and Inderjeet Singh, our resort embodies their unwavering 
                                    commitment to luxury, tradition, and unforgettable experiences.
                                </p>
                            </Col>
                            <Col lg="8" className="about-image">
                                <img src={image} alt="Luxury Retreat" className="luxury-image" />
                            </Col>
                        </Row>
                        <Row className='mt-5'>
                            <Col lg="8" className="about-image">
                                <img src={image} alt="Luxury Retreat" className="luxury-image" />
                            </Col>
                            <Col lg="4" className="about-text">
                                <h2>YOUR ULTIMATE RETREAT</h2>
                                <p>
                                    Discover Golden Blossom Imperial Resort, a destination unlike any other where luxury isn't just a feature but a way of life. 
                                    Nestled along the enchanting highway to Ayodhya and founded by Rajendra Singh and Inderjeet Singh, our resort embodies their unwavering 
                                    commitment to luxury, tradition, and unforgettable experiences.
                                </p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}
