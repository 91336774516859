import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import classname from "classnames";

//i18n 
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const MenuBar = props => {

  const [dashboard, setdashboard] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [competition, setcompetition] = useState(false);


  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }


  return (
    <React.Fragment>
          
              
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault();
                      setdashboard(!dashboard);
                    }}
                    to="/"
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Know Us")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu")}>
                    <Link to="/" className="dropdown-item">
                      {props.t("Our Team")}
                    </Link>
                    <Link to="/" className="dropdown-item">
                      {props.t("Who We Are")}
                    </Link>
                    <Link to="/" className="dropdown-item">
                      {props.t("For Community")}
                    </Link>
                    <Link to="/" className="dropdown-item">
                      {props.t("For Investors")}
                    </Link>
                    <Link to="/" className="dropdown-item">
                      {props.t("For Participators")}
                    </Link>
                    <Link to="/" className="dropdown-item">
                      {props.t("For Professionals")}
                    </Link>
                    <Link to="/" className="dropdown-item">
                      {props.t("For Visitors")}
                    </Link>
                    <Link to="/" className="dropdown-item">
                      {props.t("For Clients")}
                    </Link>
                    <hr className="mt-0 pt-0" />
                    <div className="dropdown">
                      <Link to="/" className="dropdown-item dropdown-toggle arrow-none">
                        {props.t("Mission")} <div className="arrow-down"></div>
                      </Link>
                      <div className={classname("dropdown-menu")}>
                        <Link to="/" className="dropdown-item"> {props.t("Vision")}</Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link to="/" className="dropdown-item dropdown-toggle arrow-none">
                        {props.t("Creating Eco System")} <div className="arrow-down"></div>
                      </Link>
                      <div className={classname("dropdown-menu")}>
                        <Link to="/" className="dropdown-item"> {props.t("Eco Services")}</Link>
                        <Link to="/" className="dropdown-item"> {props.t("Eco Hub")}</Link>
                      </div>
                    </div>

                  </div>
                </li>


                <li className="nav-item dropdown">
                  <Link to="/" className="nav-link dropdown-togglez arrow-none">
                    <i className="bx bx-customize me-2"></i>{props.t("Investors")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu")}>
                    <div className="dropdown">
                      <Link to="/" className="dropdown-item dropdown-toggle arrow-none">
                        {props.t("Invest With Us")} <div className="arrow-down"></div>
                      </Link>
                      <div className={classname("dropdown-menu")}>
                        <Link to="/" className="dropdown-item"> {props.t("Download - Pitch Deck")}</Link>
                      </div>
                    </div>
                      <Link to="/" className="dropdown-item dropdown-toggle arrow-none">
                        {props.t("Scholarship")}
                      </Link>
                      <Link to="/" className="dropdown-item dropdown-toggle arrow-none">
                        {props.t("Sponsorship")} 
                      </Link>
                      <Link to="/" className="dropdown-item dropdown-toggle arrow-none">
                        {props.t("Promotion & Branding")} 
                      </Link>
                      <Link to="/" className="dropdown-item dropdown-toggle arrow-none">
                        {props.t("Partner & Barter")} 
                      </Link>
                  </div>
                </li>

              {/* Health & Fitness */}

                <li className="nav-item dropdown">
                  <Link to="/" className="nav-link dropdown-togglez arrow-none"><i className="bx bx-customize me-2"></i>
                    {props.t("Health & Fitness")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                      <Link to="/" className="dropdown-item" >{props.t("Exercises")}</Link>
                      <Link to="/" className="dropdown-item" >{props.t("Diets ")} </Link>
                      <Link to="/" className="dropdown-item" >{props.t("Fitness Calculator")} </Link>
                      <Link to="/" className="dropdown-item" >{props.t("Self-Assessment")}</Link>
                      <Link to="/" className="dropdown-item" >{props.t("Fitness Test")}</Link>
                      <Link to="/dt-lock" className="dropdown-item" > {props.t("Training/Coaching")}</Link>
                  </div>
                </li>

              {/* Services*/}

                <li className="nav-item dropdown">
                  <Link to="/"className="nav-link dropdown-togglez arrow-none">
                    <i className="bx bx-customize me-2"></i>
                    {props.t("Services")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                      <Link to="https://jobs.fittestwarrior.com/" className="dropdown-item" >{props.t("Jobs")}</Link>
                      <Link to="https://blogs.fittestwarrior.com/" className="dropdown-item" >{props.t("Blogs")} </Link>
                      <Link to="/" className="dropdown-item" >{props.t("Consultancy")} </Link>
                      <Link to="/" className="dropdown-item" >{props.t("Time Table")}</Link>
                      <Link to="https://Community.fittestwarrior.com/" className="dropdown-item" >{props.t("Community Networking")}</Link>
                      <Link to="/" className="dropdown-item" > {props.t("Promotion & Branding")}</Link>
                      <Link to="/dt-lock" className="dropdown-item" > {props.t("Scholarship & Sponsorship")}</Link>
                      <Link to="/dt-lock" className="dropdown-item" > {props.t("Gym Management")}</Link>
                      <Link to="/dt-lock" className="dropdown-item" > {props.t("Live Broadcast")}</Link>
                      <Link to="/dt-lock" className="dropdown-item" > {props.t("Education")}</Link>
                      <Link to="https://Events.fittestwarrior.com/" className="dropdown-item" > {props.t("Events")}</Link>
                      <Link to="/dt-lock" className="dropdown-item" > {props.t("Competition")}</Link>
                  </div>
                </li>

                  {/* Social & Media  */}

              <li className="nav-item dropdown">
                  <Link to="/dt-lock"className="nav-link dropdown-togglez arrow-none">
                    <i className="bx bx-customize me-2"></i>
                    {props.t("Social & Media")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                      <Link to="/" className="dropdown-item" >{props.t("Stories")}</Link>
                      <Link to="/" className="dropdown-item" >{props.t("Networking")} </Link>
                      <Link to="/" className="dropdown-item" >{props.t("Knowledge")} </Link>
                      <Link to="/" className="dropdown-item" >{props.t("Podcast")}</Link>
                      <Link to="/dt-lock" className="dropdown-item" >{props.t("Media")}</Link>
                  </div>
                </li>

                {/* Hysportx */}
              <li className="nav-item dropdown">
                  <Link to="/dt-lock"className="nav-link dropdown-togglez arrow-none">
                    <i className="bx bx-customize me-2"></i>
                    {props.t("Hysportx")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                      <Link to="/dt-lock" className="dropdown-item" >{props.t("HYSPORTX ")}</Link>
                  </div>
                </li>
                <li className="nav-item dropdown"><Link to="https://Events.fittestwarrior.com/" className="nav-link dropdown-togglez arrow-none" ><i className="bx bx-customize me-2"></i>{props.t("Events")}</Link></li>
                <li className="nav-item dropdown"><Link to="https://blogs.fittestwarrior.com/" className="nav-link dropdown-togglez arrow-none" ><i className="bx bx-customize me-2"></i>{props.t("Blogs")}</Link></li>
    </React.Fragment>
  );
};

MenuBar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(MenuBar))
);
