import React, { Component } from "react"
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Button,
} from "reactstrap"

// Carousel images
import img2 from "assets/images/slider/img2.jpg"
import img3 from "assets/images/slider/img3.jpg"
import img4 from "assets/images/slider/img4.jpg"

const items = [
    {
        src: img2,
        altText: "Enjoy a streamlined and easy-to-use platform designed for a fitness journey like no other.",
        caption: "Fittest Warrior - The Ultimate Sports and Fitness Experience",
    },
    {
        src: img4,
        altText: "Navigate through our advanced tools and resources for peak performance and wellness.",
        caption: "Explore a Seamless Path to Fitness Mastery",
    },
    {
        src: img3,
        altText: "Elevate your workout routines with expert guidance and cutting-edge technology.",
        caption: "Achieve Your Fitness Goals with Fittest Warrior",
    },
]

class SlideMain extends Component {
    constructor(props) {
        super(props)
        this.state = { activeIndex: 0 }
        this.next = this.next.bind(this)
        this.previous = this.previous.bind(this)
        this.goToIndex = this.goToIndex.bind(this)
        this.onExiting = this.onExiting.bind(this)
        this.onExited = this.onExited.bind(this)
    }

    onExiting() {
        this.animating = true
    }

    onExited() {
        this.animating = false
    }

    next() {
        if (this.animating) return
        const nextIndex =
            this.state.activeIndex === items.length - 1
                ? 0
                : this.state.activeIndex + 1
        this.setState({ activeIndex: nextIndex })
    }

    previous() {
        if (this.animating) return
        const nextIndex =
            this.state.activeIndex === 0
                ? items.length - 1
                : this.state.activeIndex - 1
        this.setState({ activeIndex: nextIndex })
    }

    goToIndex(newIndex) {
        if (this.animating) return
        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { activeIndex } = this.state

        const slides = items.map((item, key) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={key}
                >
                    <img
                        src={item.src}
                        alt={item.altText}
                        className="d-block img-fluid w-100 carousel-image"
                    />
                    <div className="carousel-overlay"></div> 
                    <CarouselCaption
                        captionHeader={item.caption}
                        captionText={item.altText}
                        className="carousel-caption-content"
                    />
                    <div className="carousel-button-container">
                        <Button
                            color="success"
                            size="lg"
                            className="text-light carousel-btn"
                        >
                            Learn More
                        </Button>
                    </div>
                </CarouselItem>
            )
        })

        return (
            <React.Fragment>
                <style>
                    {`
                        .carousel-image {
                            height: 700px;
                            object-fit: cover;
                            filter:blur(1px);
                        }

                        .carousel-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(180deg, rgba(0,0,0,0.2), rgba(0,0,0,0.6));
                            z-index: 1;
                           
                        }

                        .carousel-caption-content {
                            position: absolute;
                            z-index: 2;
                            bottom: 40%;
                            left: 10%;
                            width: 50%;
                            background: rgba(0, 0, 0, 0.5);
                            padding: 15px;
                            border-radius: 10px;
                            color: #fff;
                            text-align: left;
                            transition: transform 0.4s ease, opacity 0.4s ease;
                            opacity: 0.9;
                             cursor:pointer;
                        }

                        .carousel-caption-content h3 {
                            font-size: 2.5rem;
                            font-weight: 700;
                            transition: transform 0.4s ease, opacity 0.4s ease;
                        }

                        .carousel-caption-content p {
                            font-size: 1.2rem;
                            font-weight: 300;
                            margin-top: 10px;
                            transition: transform 0.4s ease, opacity 0.4s ease;
                        }

                        /* Hover effect on the text */
                        .carousel-caption-content:hover {
                            transform: scale(1.05); /* Slight zoom on hover */
                            opacity: 1;
                        }

                        .carousel-caption-content:hover h3,
                        .carousel-caption-content:hover p {
                            transform: translateY(-5px); /* Move text slightly upward */
                        }

                        .carousel-button-container {
                            position: absolute;
                            bottom: 30%;
                            left: 15%;
                            transform: translateX(-50%);
                            z-index: 2;
                        }
                            .carousel-btn{
                            border:none;
                            border-radius:5px;
                            }

                        .carousel-button {
                            background-color: #28a745;
                            border: none;
                            transition: background-color 0.3s ease-in-out;
                        }

                        .carousel-button:hover {
                            background-color: #218838;
                        }

                        @media (max-width: 768px) {
                            .carousel-caption-content h3 {
                                font-size: 1.8rem;
                            }

                            .carousel-caption-content p {
                                font-size: 1rem;
                            }
                        }

                        @media (max-width: 576px) {
                            .carousel-caption-content {
                                width: 80%;
                            }

                            .carousel-caption-content h3 {
                                font-size: 1.5rem;
                            }

                            .carousel-caption-content p {
                                font-size: 0.9rem;
                            }
                        }
                    `}
                </style>

                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                    <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={this.goToIndex}
                    />
                    {slides}
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={this.previous}
                    />
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={this.next}
                    />
                </Carousel>
            </React.Fragment>
        )
    }
}

export default SlideMain
