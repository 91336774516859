import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Carousel,
    CarouselItem,
    CarouselControl,
    Button,
} from "reactstrap";
import classnames from "classnames";
import './slider_custom.css'; // Import custom CSS

import img1 from "assets/images/slider/img1.jpg";
import img2 from "assets/images/slider/img2.jpg";
import img3 from "assets/images/slider/img3.jpg";
import img5 from "assets/images/slider/img5.jpg";
import img6 from "assets/images/slider/img6.jpg";
import img7 from "assets/images/slider/img7.jpg";

const DataMapping = {
    Events: [
        { image: img5, title: "Inter School", desc: "Inter-school competition starts", date: "" },
        { image: img6, title: "Intermediate", desc: "Intermediate level competition starts", date: "" },
        { image: img7, title: "High Level", desc: "High-level school competition starts", date: "" },
        { image: img1, title: "Event 4", desc: "Event description", date: "" },
        { image: img2, title: "Event 5", desc: "Event description", date: "" },
        { image: img3, title: "Event 6", desc: "Event description", date: "" },
    ],
    Blogs: [
        { image: img5, title: "Blog 1", desc: "Blog description", date: "" },
        { image: img6, title: "Blog 2", desc: "Blog description", date: "" },
        { image: img7, title: "Blog 3", desc: "Blog description", date: "" },
        { image: img1, title: "Blog 4", desc: "Blog description", date: "" },
        { image: img2, title: "Blog 5", desc: "Blog description", date: "" },
        { image: img3, title: "Blog 6", desc: "Blog description", date: "" },
    ],
    Jobs: [
        { image: img5, title: "Job 1", desc: "Job description", date: "" },
        { image: img6, title: "Job 2", desc: "Job description", date: "" },
        { image: img7, title: "Job 3", desc: "Job description", date: "" },
        { image: img1, title: "Job 4", desc: "Job description", date: "" },
        { image: img2, title: "Job 5", desc: "Job description", date: "" },
        { image: img3, title: "Job 6", desc: "Job description", date: "" },
    ],
    Exercises: [
        { image: img5, title: "Exercise 1", desc: "Exercise description", date: "" },
        { image: img6, title: "Exercise 2", desc: "Exercise description", date: "" },
        { image: img7, title: "Exercise 3", desc: "Exercise description", date: "" },
        { image: img1, title: "Exercise 4", desc: "Exercise description", date: "" },
        { image: img2, title: "Exercise 5", desc: "Exercise description", date: "" },
        { image: img3, title: "Exercise 6", desc: "Exercise description", date: "" },
    ],
    Diets: [
        { image: img5, title: "Diet 1", desc: "Diet description", date: "" },
        { image: img6, title: "Diet 2", desc: "Diet description", date: "" },
        { image: img7, title: "Diet 3", desc: "Diet description", date: "" },
        { image: img1, title: "Diet 4", desc: "Diet description", date: "" },
        { image: img2, title: "Diet 5", desc: "Diet description", date: "" },
        { image: img3, title: "Diet 6", desc: "Diet description", date: "" },
    ],
};

const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
};

const CarouselSection = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [likedItems, setLikedItems] = useState({}); 

    const toggleLike = (index) => {
        setLikedItems((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const chunks = chunkArray(data, 4);

    const next = () => {
        if (animating) return;
        setActiveIndex(prevIndex => (prevIndex === chunks.length - 1 ? 0 : prevIndex + 1));
    };

    const previous = () => {
        if (animating) return;
        setActiveIndex(prevIndex => (prevIndex === 0 ? chunks.length - 1 : prevIndex - 1));
    };

    const slides = chunks.map((group, idx) => (
        <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={idx}>
            <Row className="g-4">
                {group.map((item, id) => (
                    <Col md={3} key={id}>
                        <Card className="custom-card bg-dark text-white h-100">
                            <div className="image-container">
                                <img
                                    src={item.image}
                                    alt={item.title}className="card-img-top"/>
                                <button
                                    type="button"
                                    className="btn like-btn"
                                    onClick={() => toggleLike(id)}
                                >
                                    <i
                                        className="bx bxs-heart"
                                        style={{ color: likedItems[id] ? "red" : "white" }}
                                    ></i>
                                </button>
                            </div>
                            <CardBody className="d-flex flex-column custom-card-body">
                                <CardTitle tag="h5" className="text-uppercase text-white">
                                    {item.title}
                                </CardTitle>
                                <CardText className="mb-3 text-truncate">{item.desc}...</CardText>
                                <Button color="light" block className="mt-auto">Join for Free</Button>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </CarouselItem>
    ));

    return (
        <Container>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
        </Container>
    );
};

const CompetitionRightSide = ({ customActiveTab, setcustomActiveTab }) => (
    <React.Fragment>
        <Nav tabs className="nav-tabs-custom nav-justified me-3">
            {Object.keys(DataMapping).map((tab, index) => (
                <NavItem key={index}>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: customActiveTab === String(index + 1) })} onClick={() => setcustomActiveTab(String(index + 1))}>
                        <span className="d-none d-sm-block fs-3">{tab}</span>
                    </NavLink>
                </NavItem>
            ))}
        </Nav>

        <TabContent activeTab={customActiveTab} className="p-3 text-muted me-lg-3">
            {Object.keys(DataMapping).map((tab, index) => (
                <TabPane tabId={String(index + 1)} key={index}>
                    <CarouselSection data={DataMapping[tab]} />
                </TabPane>
            ))}
        </TabContent>
    </React.Fragment>
);

export default CompetitionRightSide;
